import L from 'leaflet';

const iconRetinaUrl = require('leaflet/dist/images/marker-icon-2x.png').default;
const iconUrl = require('leaflet/dist/images/marker-icon.png').default;
const shadowUrl = require('leaflet/dist/images/marker-shadow.png').default;

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl
});

const customIcon = new L.Icon({
  iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png', // Path to the custom icon in the public directory
  iconSize: [25, 35], // Size of the icon
  iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
  shadowSize: [41, 41],
  shadowAnchor: [12, 41]
});

export default customIcon;
