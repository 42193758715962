import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import customIcon from './leafletConfig'; // Import the custom icon configuration
import './MapComponent.css'; // Import CSS for styling
import Modal from '../modal/Modal';

const MapComponent = () => {
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [colorMap, setColorMap] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    // Fetch the GeoJSON data
    // fetch('/slovenia5croatia6.geojson')
    //   .then(response => response.json())
    //   .then(data => setGeoJsonData(data))
    //   .catch(error => console.error('Error fetching GeoJSON:', error));
  }, []);

  // Function to create a custom marker icon for GeoJSON points
  const onEachFeature = (feature, layer) => {
    // Attach a click event to the layer
    layer.on({
      click: () => {
        setSidebarOpen(true);
        setSelectedFeature({ id: feature.properties.osm_id, properties: { ...feature.properties } });
        console.log(feature.properties);
      }
    });
  };

  // Function to generate a random hex color
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };


  // Define the style function using the colorMap
  const style = (feature) => {
    const id = feature.properties['geozone'];
    let color = colorMap[id];
    if (!color) {
      color = getRandomColor();
      setColorMap((prevColorMap) => ({
        ...prevColorMap,
        [id]: color,
      }));
    }
    return {
      fillColor: color,
      opacity: 1,
      color: color,
      fillOpacity: 0.5
    };
  };

  const pointToLayer = (feature, latlng) => {
    return L.marker(latlng, { icon: customIcon });
  };

  const handleInputChange = (event, key) => {
    const value = event.target.value;
    setSelectedFeature(prevState => ({
      ...prevState,
      properties: {
        ...prevState.properties,
        [key]: value
      }
    }));
  };

  const download = () => {
    if (!geoJsonData) {
        console.error('No data to download');
        return;
      }
      const dataStr = JSON.stringify(geoJsonData, null, 2); // Pretty print JSON
      const blob = new Blob([dataStr], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'data.json';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);  
  }

  const handleSave = () => {
    if (geoJsonData && selectedFeature) {
      const updatedGeoJsonData = {
        ...geoJsonData,
        features: geoJsonData.features.map(feature => {
            console.log(feature, feature.id, selectedFeature, selectedFeature.osm_id, feature.id === selectedFeature.osm_id)
          if (feature.properties.osm_id === selectedFeature.id) {
            console.log('a')
            return {
              ...feature,
              properties: { ...selectedFeature.properties }
            };
          }
          return feature;
        })
      };
      setGeoJsonData(updatedGeoJsonData);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const json = JSON.parse(e.target.result);
        setGeoJsonData(json);
        setIsModalOpen(false); // Close the modal after loading the file
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className="container">
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onFileChange={handleFileChange} />    
      <div className="map-container">
        <MapContainer center={[46.0569, 14.5058]} zoom={6} style={{ position: 'absolute', height: '100%', width: '100%' }}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
          {geoJsonData && (
            <GeoJSON
              key={JSON.stringify(geoJsonData)} // Use key prop to force re-render
              data={geoJsonData}
              pointToLayer={pointToLayer}
              style={style}
              onEachFeature={onEachFeature}
            />
          )}
        </MapContainer>
      </div>
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <img src='https://telenavis.com/wp-content/uploads/2021/03/telenavis-logo.png' />
        {selectedFeature && (
          <div>
            <h2>Geozone Formatter</h2>
            {Object.keys(selectedFeature.properties).map((key) => (
              <div key={key} className="input-group">
                <label htmlFor={key}>{key}</label>
                <input
                  id={key}
                  type="text"
                  value={selectedFeature.properties[key] === null ? '' : selectedFeature.properties[key]}
                  onChange={(e) => handleInputChange(e, key)}
                />
              </div>
            ))}
            <button className="save-button" onClick={handleSave}>Save</button>
            <button className="close-button" onClick={() => setSidebarOpen(false)}>Close</button>
            <button className="save-button" onClick={download}>Download GEOJson</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MapComponent;
